$dark-primary-color: #202020;
$darkish-primary-color: #282828;
$primary-color: #353535;
$lightish-primary-color: #484848;
$light-primary-color: #606060;
$dark-secondary-color: hsl(183, 81%, 15%);
$darkish-secondary-color: hsl(183, 100%, 38%);
$secondary-color: #20D8E0;
$lightish-secondary-color: hsl(183, 76%, 70%);
// $light-secondary-color: hsl(55, 100%, 80%);
:root {
	--text-color: white;
}
body {
	accent-color: $lightish-secondary-color;
	background-color: $primary-color;
	display: flex;
	flex-direction: column;
	font-family: sans-serif;
	margin: 0;
	min-height: 100vh;
}
[lang="en"] [lang]:not([lang="en"]) {
	font-style: italic;
}
header {	
	box-shadow: 0 2px 5px 0 rgba(0,0,0,0.26);
	padding: 10px;
	>* {
		align-items: flex-end;
		display: inline-flex;
		flex-wrap: wrap;
		justify-content: center;
	}
	a {
		text-decoration: none;
	}
	a:hover {
		filter: brightness(1.4);
	}
	img {
		image-rendering: crisp-edges;
		filter: drop-shadow(3px 3px 2px #000);
	}
	h1 {
		color: #EEE;
		display: inline-block;
		margin: 0;
		padding: 20px;
		vertical-align: top;
	}
}
h1 {
	font-size: 60px;
	line-height: 60px;
}
header, footer {
	background-color: $dark-primary-color;
	text-align: center;
}
nav {
	display: flex;
	font-size: 20px;
	font-weight: bold;
	margin: 15px auto 5px;
	max-width: 780px;
	width: 100%;
}
nav>ul {
	display: inline-flex;
	justify-content: space-between;
	align-items: stretch;
}
nav ul {
	list-style: none;
	margin: 0;
	padding: 0;
	width: 101%;
}
nav ul>li {
	margin-right: 0.7%;
	text-align: center;
}
nav ul>li:last-child {
	margin-right: 0;
}
nav ul li {
	margin-bottom: 0 !important;
}
nav ul li a {
	background-color: $lightish-primary-color !important;
	box-shadow: 0 2px 5px 0 rgba(0,0,0,0.26);
	box-sizing: border-box;
	color: $secondary-color;
	cursor: pointer;
	display: inline-block;
	height: 100%;
	padding: 9px 10px !important;
}
nav ul li a:hover {
	background-color: $light-primary-color !important;
	color: $lightish-secondary-color;
}
nav a {
	text-decoration: none;
}
aside {
	padding: 5px;
	z-index: 60;
	width: calc((100vw - 800px)/2);
}
aside .adsbygoogle {
	max-width: 305px;
}
.ad {
	text-align: center;
}
aside.ad {
	position: sticky;
	top: 25px;
}
aside.ad>div {
	height: 100%;
	margin: auto;
	max-width: 305px;
}
.left {
	clear: left !important;
	float: left;
}
.right {
	float: right;
}
.content-container {
	flex-grow: 1;
}
main {
	flex-grow: 1;
	padding-bottom: 2vw;
}
section, article, details {
	background-color: $darkish-primary-color;
	box-shadow: 0 2px 5px 0 rgba(0,0,0,0.26);
	color: var(--text-color);
	max-width: 760px;
	margin: 15px auto;
	padding: 10px;
	img {
		max-width: 760px;
	}
}
h2, h2 a, h3, h4, h5, h6, summary, dt {
	color: $secondary-color;
	font-weight: bold;
	margin: 0 0 15px 0;
}
dt {
	margin: 10px 0 10px 20px;
}
h2, summary {
	font-size: 24px;
}
summary {
	cursor: pointer;
}
details:not([open])>summary {
	margin: 0;
}
p:first-child {
	margin-top: 0;
}
p:last-child {
	margin-bottom: 0;
}
a {
	color: $secondary-color;
}
#search-section form {
	display: flex;
	button {
		height: 30px;
		margin-left: 5px;
	}
}
#search-box {
	box-sizing: border-box;
	flex-grow: 1;
	font-size: 18px;
	height: 30px;
}
input[type="number"] {
	background-color: $dark-secondary-color;
	border: 1px solid $darkish-secondary-color;
	color: white;
	padding: 4px 5px;
	-moz-appearance: textfield;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
button {
	background-color: $darkish-secondary-color;border: none;
	box-shadow: 0 2px 3px 0 rgba(0,0,0,0.14);
	color: #EEE;
	cursor: pointer;
	font-weight: bold;
	padding: 5px 8px;
}
button:hover {
	filter: brightness(1.14);
}
li:not(:last-child) {
	margin-bottom: 8px;
}
.episode-list {
	border: var(--text-color) solid 2px;
	border-radius: 5px;
	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.28);
	display: inline-block;
	margin: 0;
	padding: 4px 8px;
	summary {
		font-size: 17px;
	}
}
footer {
	color: #EEE;
	padding: 20px;
	a {
		color: $lightish-secondary-color;
	}
}